import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import styled from 'styled-components'
import Section from '../../styles/Section'
import BaseOuterSection from '../../styles/OuterSection'
import { colors, fonts, media } from '../../../style-utils'
import Experts from '../../../assets/experts.svg'
import Interface from '../../../assets/interfaces.svg'
import Cross from '../../../assets/cross.svg'

const OuterSection = styled(BaseOuterSection)`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  padding-top: unset;
`

const SectionBox = styled(Section)`
  margin-top: unset;
  padding-top: unset;
  padding-bottom: 2rem;
`
const SectionBox2 = styled(Section)`
  margin-top: unset;
  padding-top: 3rem;
  padding-bottom: 3rem;
  ${media.desktop`
    padding-top: 5rem;
    padding-bottom: 5rem;
  `};
`

const SvgContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const InfoItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-self: center;
  margin-top: 2rem;
  margin-bottom: 4rem;
  &:last-of-type {
    padding-bottom: 0;
  }
  ${media.desktop`
    width: 100%;
    padding-bottom: 0;
    flex-direction: row;
    justify-content: space-around;
  `};
`

const InfoImageBox = styled.div`
  display: flex;
  justify-content: center;
  ${media.desktop`
    height: 270px;
    margin-bottom: 50px;
    order: ${props => props.order || '2'};
  `};
  ${media.lgDesktop`
    height: 270px;
    margin-bottom: 50px;
  `};
`

const InfoImage = styled.img`
  width: 12rem;
  user-select: none;
  ${media.tablet`
    width: 14rem;
  `};
  ${media.desktop`
    width: 18rem;
  `};
`

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  order: 2;
  ${media.desktop`
    width: 40%;
    text-align: left;
  `};
`

const InfoTitle = styled.h2`
  font-weight: 700;
  font-family: ${fonts.bebas};
  font-size: 1.4rem;
  color: ${colors.lightGrey};
`

const InfoDescription = styled.div`
  color: ${colors.lightGrey};
  font-weight: lighter;
  font-size: 0.9rem;
  font-family: ${fonts.helvetica};
`

const StyledTitle = styled.h1`
  color: black;
  text-align: center;
  font-family: ${fonts.bebas};
  text-align: center;
  font-size: 2.2rem;
  border: solid 3px ${colors.main};
  width: 11rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 2rem;
  ${media.tablet`
    font-size: 2.4rem;
    margin-bottom: 1rem;
  `};
  ${media.desktop`
    font-size: 3rem;
    margin-bottom: 4rem;
    width: 14rem;
  `};
`
const QuoteText = styled.blockquote`
  font-style: italic;
  font-size: 1.2rem;
  font-family: ${fonts.avenir};
  color: #ffffff;
  text-align: center;
  margin: 0;
  ${media.tablet`
    font-size: 1.5rem;
    line-height: normal;
    line-height: 1.47;
    width: 80%;
    margin: auto;
  `};

  ${media.desktop`
    font-size: 1.6rem;
    line-height: normal;
    line-height: 1.47;
    width: 80%;
    margin: auto;
  `};
`
const OuterSectionQuote = styled(BaseOuterSection)`
  background-color: ${colors.main};
`

const SectionServices = () => (
  <OuterSection>
    <SectionBox id="wat-doen-we" className="clearfix">
      <StyledTitle>Wat doen we</StyledTitle>
      <SvgContainer>
        <InfoItemContainer>
          <InfoImageBox>
            <ScrollAnimation animateIn="bounceInRight" animateOnce>
              <InfoImage src={Experts} alt="Experts" />
            </ScrollAnimation>
          </InfoImageBox>
          <InfoBox>
            <InfoTitle>Frontend Experts</InfoTitle>
            <InfoDescription>
              Een team van gelijkgestemden, gepassioneerd door frontend development, up to date met
              de laatste nieuwe technologieën. Onze guru&rsquo;s worden ingezet in de uitdagende
              Appeel.io projecten maar kunnen tevens bij onze klanten op kantoor aan de slag.
            </InfoDescription>
          </InfoBox>
        </InfoItemContainer>
        <InfoItemContainer>
          <InfoImageBox order="3">
            <ScrollAnimation animateIn="bounceInLeft" animateOnce>
              <InfoImage src={Interface} alt="Interface" />
            </ScrollAnimation>
          </InfoImageBox>
          <InfoBox>
            <InfoTitle>Gebruiksvriendelijke interfaces</InfoTitle>
            <InfoDescription>
              Appeel.io staat graag in voor het ontwerpen en implementeren van schermen die de
              eindgebruikers van uw applicatie een nooit eerder geziene gebruikerservaring zullen
              bieden. Hiertoe gaan we gebruik maken van de meest recente technische oplossingen die
              we voorhanden hebben en zullen we onze klant ook helpen bij het maken van de juiste
              keuzes binnen de wondere JavaScript wereld.
            </InfoDescription>
          </InfoBox>
        </InfoItemContainer>
        <InfoItemContainer>
          <InfoImageBox>
            <ScrollAnimation animateIn="bounceInRight" animateOnce>
              <InfoImage src={Cross} alt="Cross" />
            </ScrollAnimation>
          </InfoImageBox>
          <InfoBox>
            <InfoTitle>Cross-Platform Desktop Applicaties</InfoTitle>
            <InfoDescription>
              Desktop applicaties gebouwd met frontend technologieën (JavaScript/HTML/CSS) die op
              alle operating systemen draaien (Mac/Windows/Linux).
            </InfoDescription>
          </InfoBox>
        </InfoItemContainer>
      </SvgContainer>
    </SectionBox>
    <OuterSectionQuote>
      <SectionBox2>
        <QuoteText>
          Ben jij gepassioneerd door frontend ontwikkeling en zou je graag uitgroeien tot een expert
          in je vak? Kom dan zeker kennis maken met Appeel.io !
        </QuoteText>
      </SectionBox2>
    </OuterSectionQuote>
  </OuterSection>
)

export default SectionServices
