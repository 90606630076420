import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ScrollAnimation from 'react-animate-on-scroll'
import styled from 'styled-components'
import Slider from '../_library/carousel'
import { colors, fonts, media } from '../../style-utils'
import CarouselButton from './Button'

const CarouselBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const CarouselTitle = styled.span`
  color: ${colors.lightGrey};
  font-weight: normal;
  font-size: 1.2rem;
  font-family: ${fonts.anton};
  padding: 0.5rem;
  margin-top: 1.2rem;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  ${media.desktop`
    text-align: left;
    margin-bottom: 0;
    font-size: 1.2rem;
    justify-content: flex-start;
  `};
`
const Title = styled.span`
  color: ${colors.lightGrey};
  font-weight: normal;
  font-size: 1.6rem;
  font-family: ${fonts.anton};
  padding: 0.5rem;
  border: solid 3px ${colors.lightRedMain};
  width: auto;
  ${media.desktop`
    text-align: left;
    margin-bottom: 0;
    font-size: 2rem;
    justify-content: flex-start;
  `};
`

const CarouselText = styled.span`
  color: black;
  text-align: center;
  font-weight: lighter;
  font-size: 1.2rem;
  font-family: ${fonts.helvetica};

  &:focus {
    outline: none;
  }
  ${media.tablet`
    font-size: 1.2rem;
    padding-left: 20px;
    padding-right: 20px;
  `};
  ${media.desktop`
    text-align: left;
    margin-bottom: 0;
    font-size: 1.2rem;
  `};
`

const CarouselButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 1rem;
  &:first-child {
    margin-right: 25px;
  }
  ${media.desktop`
    justify-content: left;
    text-align: left;
  `};
`

export default class Carousel extends Component {
  settings = {
    hasButtons: false,
    autoplay: true,
    autoplaySpeed: 6000,
    style: {
      marginLeft: '-20px',
    },
  }

  setSliderRef = element => {
    this.slider = element
  }

  onArrowClickPrevious = () => this.slider.previous()

  onArrowClickNext = () => this.slider.next()

  render() {
    const { getIndex } = this.props

    return (
      <ScrollAnimation animateIn="bounceInLeft" animateOnce delay={500}>
        <Slider ref={this.setSliderRef} {...this.settings} onSlideChange={getIndex}>
          <CarouselBox>
            <CarouselTitle>
              <Title>UI & UX</Title>
            </CarouselTitle>
            <CarouselText>
              Betrek Appeel.io in uw plannen om de grafische schil rond uw applicatie aan te passen
              aan de noden van deze tijd.
            </CarouselText>
          </CarouselBox>
          <CarouselBox>
            <CarouselTitle>
              <Title>Usability</Title>
            </CarouselTitle>
            <CarouselText>
              Appeel.io bouwt de broodnodige gebruiksvriendelijkheid in uw applicaties zodat uw
              eindgebruikers graag met uw software werken.
            </CarouselText>
          </CarouselBox>
          <CarouselBox>
            <CarouselTitle>
              <Title>JavaScript</Title>
            </CarouselTitle>
            <CarouselText>
              Wij helpen onze klanten het bos door de bomen te zien in een zeer snel evoluerende
              wereld van JavaScript technologieën.
            </CarouselText>
          </CarouselBox>
        </Slider>
        <CarouselButtonsContainer>
          <CarouselButton left onClick={this.onArrowClickPrevious} />
          <CarouselButton onClick={this.onArrowClickNext} />
        </CarouselButtonsContainer>
      </ScrollAnimation>
    )
  }
}

Carousel.propTypes = {
  getIndex: PropTypes.func,
}

Carousel.defaultProps = {
  getIndex: () => {},
}
