import React from 'react'
import styled from 'styled-components'
import { colors, fonts, media } from '../../../style-utils'
import Section from '../../styles/Section'
import BaseOuterSection from '../../styles/OuterSection'

const OuterSection = styled(BaseOuterSection)`
  background-color: ${colors.customWhite};
  padding: unset;
  margin-bottom: unset;
`

const JobSection = styled(Section)`
  background-color: ${colors.customWhite};
  padding: 1rem 2rem;
  margin-bottom: 2rem;

  ${media.desktop`
    padding: 1rem 4rem;
  `};
`

const TitleSection = styled(Section)`
  background-color: ${colors.customWhite};
  padding-top: unset;
  margin-top: unset;
  margin-bottom: unset;
  padding-bottom: unset;
`

const Title = styled.h1`
  color: #333333;
  text-align: center;
  font-family: ${fonts.bebas};
  text-align: center;
  font-size: 3rem;
  border: solid 3px ${colors.main};
  width: 8rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2rem;

  ${media.tablet`
    margin-bottom:2rem;
  `};
`

const Box = styled.div`
  ${media.tablet`
    margin-bottom:2rem;
  `};

  ${media.desktop`
    float: left;
    width: 60%;

    &:nth-of-type(2n-1) {
      padding-right: 2rem;
    }
    &:nth-of-type(2n) {
      padding-left: 2rem;
    }
  `};
`

const SubTitle = styled.h2`
  font-weight: 700;
  color: ${colors.main};
  font-size: 1.1rem;
  text-align: left;
  margin-top: 0;
`

const SubTitle2 = styled(SubTitle)`
  font-weight: 700;
  font-family: ${fonts.bebas};
  font-size: 1.6rem;
  color: ${colors.lightGrey};
  ${media.tablet`
    text-align: left;
  `};
`

const Paragraph = styled.p`
  color: ${colors.lightGrey};
  font-weight: lighter;
  font-size: 0.8rem;
  font-family: ${fonts.helvetica};
  ${media.tablet`
    text-align: left;
    font-size: 0.9rem;
  `};
`

const ApplyButton = styled.a`
  font-family: ${fonts.bebas};
  font-size: 1.3rem;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  border: solid 3px ${colors.main};
  color: ${colors.lightGrey};
  background-color: ${colors.customWhite};
  padding: 1rem 1rem;
  cursor: pointer;
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;
  outline: none;
  &:hover * {
    color: ${colors.main};
  }
  &:hover,
  &:active {
    border: solid 3px ${colors.main};
    color: ${colors.main};
  }
  img {
    display: inline-block;
    max-width: 100%;
    margin-left: 20px;
  }
  ${media.tablet`
    padding: 1.5rem 2rem;
    font-size: 1.6rem;
  `};
  ${media.desktop`
    margin-left: 0;
  `};
`
const LinkText = styled.span`
  display: flex;
`

const LinkMore = styled.span`
  font-family: ${fonts.bebas};
  font-size: 1.2rem;
  color: ${colors.lightGrey};
  outline: none;
`

const letter = '  </>'

const SectionJob = () => (
  <>
    <OuterSection id="jobs">
      <TitleSection>
        <Title>Jobs</Title>
        <Box>
          <SubTitle2>Ontwikkel jezelf!</SubTitle2>
          <Paragraph>
            Wil jij jezelf verder ontwikkelen in een gedreven team met focus op kwaliteit en
            innovatie? Wij geven jou die vrijheid. Enkele dagen in de maand krijg je van ons de tijd
            om nieuwe technologieën te ontdekken, je kennis te verbeteren en die te delen met je
            collega’s.
          </Paragraph>
        </Box>
      </TitleSection>
    </OuterSection>
    <JobSection className="clearfix">
      <ApplyButton href="https://jobs.appeel.io/">
        <LinkText>
          Frontend developer
          {letter}
        </LinkText>
        <LinkMore>Meer</LinkMore>
      </ApplyButton>
    </JobSection>
  </>
)

export default SectionJob
