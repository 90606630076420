import React, { Component } from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import styled from 'styled-components'
import Carousel from '../../carousel'
import Largesvg from '../../LargeSvg'
import Section from '../../styles/Section'
import { media } from '../../../style-utils'
import svgBackground from '../../../assets/svg-background.svg'
import TextureBackground from '../../../assets/texture-header.svg'

const Container = styled.div`
  position: relative;
  ${media.desktop`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    & > * {
      flex-grow: 1;
      width: 50%;
    }
  `};
`

const ContainerBox = styled(Section)`
  position: relative;
`

const SvgContainer = styled.img`
  position: absolute;
  z-index: -1;
  ${media.desktop`
    position: absolute;
  `};
`

const ImgContainer = styled.div`
  position: relative;
  width: 50vw;
  margin: 0 auto;
  ${media.desktop`
    width: 40vw;
  `};
`

const Svgtexture = styled.img`
  display: none;
  ${media.desktop`
    display: flex;
    width: 50vw;
    position: absolute;
    left: 0vw;
    top: 40vh;
  `};
`

export default class SectionCarousel extends Component {
  state = { index: 0 }

  getIndex = newIndex => this.setState({ index: newIndex })

  render() {
    const { index } = this.state

    return (
      <ContainerBox id="wie-zijn-we">
        <Container>
          <ScrollAnimation animateIn="fadeInRight" animateOnce>
            <ImgContainer>
              <SvgContainer src={svgBackground} alt="svg" />
              <Largesvg index={index} />
            </ImgContainer>
          </ScrollAnimation>
          <Carousel getIndex={this.getIndex} />
        </Container>
        <Svgtexture draggable="false" src={TextureBackground} alt="svg" />
      </ContainerBox>
    )
  }
}
